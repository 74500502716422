// @ts-nocheck

import type { AppDispatch, RootState } from 'src/store'
import { Button, ButtonGroup, Dropdown, DropdownButton, FloatingLabel, Form, InputGroup } from 'react-bootstrap'
import { calculateProcessingFee, dateTitles, dates, daysOfMonths, monthTitles, parseSubscription } from 'modules/utils'
import { donationTypeNames, sitesWithDonateDescription } from 'modules/siteContents'
import {
  injectPlan,
  inputDonationAmount,
  setDonationAmount,
  setDonationType,
  setMonthlyOtherAmount,
  setPaymentDate,
  setPaymentMonth,
  setValidationError,
} from 'actions/optionsActions'
import { setData } from 'actions/contactActions';
import { setShowDetails, switchView } from 'slices/rootSlice'

import type { AppRouteProps } from 'src/types/app'
import type { ConnectedProps } from 'react-redux'
import React from 'react'
import ReactPlayer from 'react-player'
import Spinner from 'sharedComponents/Spinner'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getCookie } from 'modules/cookie'
import moment from 'moment-timezone'
import numeral from 'numeral'
import queryString from 'query-string'
import { setIncludeProcessingFee } from 'actions/paymentActions'
import { withRouter } from 'src/routes/WithRouter'
import GiftMenu from '../../components/GiftMenu'

const cidTextDict = {
  STS: {
    '7018X000001uAsvQAE': ' - you can be certain your donation is effective. Your gift will help fund a flight of life-affirming nurses fully equipped to provide live chat for mobile medical clinics and pregnancy health clinics. With your first gift of any amount, we’ll send you a ',
    '7018X000001yOSEQA2': ' - you can be certain your donation is effective. Your gift will help fund a flight of life-affirming nurses fully equipped to provide live chat for mobile medical clinics and pregnancy health clinics. With your first gift of any amount, we’ll send you a ',
    '7018X000001yOS9QAM': ' - you can be certain your donation is effective. Your gift will help fund a flight of life-affirming nurses fully equipped to provide live chat for mobile medical clinics and pregnancy health clinics. With your first gift of any amount, we’ll send you a ',
  }
}

const mapState = (store : RootState, ownProps: AppRouteProps) => ({
  showDetails: store.root.showDetails,
  plans: store.options.plans,
  customer: store.root.customer,
  loadingCustomer: store.root.loadingCustomer,
  donationType: store.options.donationType,
  donationAmount: store.options.donationAmount,
  currentDonationPlan: store.options.currentDonationPlan,
  validationError: store.options.validationError,
  monthlyOtherAmount: store.options.monthlyOtherAmount,
  paymentDate: store.options.paymentDate,
  paymentMonth: store.options.paymentMonth,
  displayValidationError: store.payment.displayValidationError,
  onlyShowTypes: store.options.onlyShowTypes,
  includeProcessingFee: store.payment.includeProcessingFee,
  processingFee: calculateProcessingFee(store.options.donationAmount, null, store.payment.type),
  subscription: store.root.subscription ? {
    ...store.root.subscription,
    ...parseSubscription(store.root.subscription, store.root.crm),
  } : null,
  subscriptionYearly: store.root.subscriptionYearly ? {
    ...store.root.subscriptionYearly,
    ...parseSubscription(store.root.subscriptionYearly, store.root.crm),
  } : null,
  campaignId: store.contact.campaignId,
  headerVideo: (store.root.campaigns.find(c => c.id === store.contact.campaignId) || {}).headerVideo,
  utmVideo: Object.keys(_.get(ownProps, 'initialUrlParams', {})).reduce((acc, cur) => {
    return acc || _.get(store.root.utmVideos, [cur, _.get(ownProps, ['initialUrlParams', cur])])
  }, null),
  charityRun: store.charityRun,
  eventRegistration: store.eventRegistration,
  campaignOptions: (store.root.campaigns.find(c => c.id === store.contact.campaignId) || {}).options,
  selectedCampaignOption: store.contact.selectedCampaignOption,
})

const actionCreators = {
  // switchView,
  setDonationType,
  setDonationAmount,
  inputDonationAmount,
  setValidationError,
  setShowDetails,
  setMonthlyOtherAmount,
  setPaymentDate,
  setPaymentMonth,
  setIncludeProcessingFee,
  injectPlan,
  setData,
}

const connector = connect(mapState, actionCreators)
type OptionsProps = ConnectedProps<typeof connector> & AppRouteProps;
interface OptionsState {
  showTaxStatus: boolean,
  giftModalOpen: boolean,
  decimal: string,
}

class Options extends React.Component<OptionsProps, OptionsState> {
  state: OptionsState = {
    showTaxStatus: false,
    giftModalOpen: false,
    decimal: '',
  }

  /**
   * 
   */
  componentDidMount() {
    const {
      // donationAmount, 
      donationType, 
      initialUrlParams: {
        term = 'monthly',
        amount,
        showTaxStatus,
        // isOther,
        isMonthly,
      },
      guestMode,
      plans, 
      setDonationType, 
      setDonationAmount, 
      setMonthlyOtherAmount, 
      setPaymentDate,
      subscription, 
      injectPlan,
      enableEvents,
      layoutConfig,
    } = this.props

    if (guestMode) {
      this.selectDonationType('one-time')
    }

    const planTerm = plans[term] || []
    const planTermIndex = (isMonthly && planTerm.length) > 2 ? 1 : 0
    // console.log(plans, term, planTermIndex)
    const planAmount = amount || plans[term][planTermIndex]

    // Inject new plan if planAmount is available from plan presets
    if (planAmount !== 'other' && plans[term] && !plans[term].includes(planAmount)) {
      injectPlan(planAmount, term)
    }
    console.log('[donate][options] available plans', plans)

    setDonationType(term)
    setDonationAmount(planAmount)
    this.props.router.standardizeDonateRoute(term, planAmount, { replace: true })

    if (!planAmount) {
      setMonthlyOtherAmount(true)
      this.focusOtherAmountInput()
    }
    else if (!plans[term].includes(amount)) {
      setMonthlyOtherAmount(true)
    }
    if (showTaxStatus) {
      this.setState({ showTaxStatus: true })
    }
    if (subscription) {
      const paymentDate = new Date(subscription.nextPaymentDate || subscription.startDate)
      setPaymentDate(paymentDate.getUTCDate() || (new Date()).getDate())
    }
  
    if (['monthly', 'yearly'].includes(donationType)) {
      this.syncPaymentDateWithSubscription()
    }

    if (layoutConfig?.roundUpCheckbox?.defaultValue !== undefined) {
      this.props.setIncludeProcessingFee(layoutConfig.roundUpCheckbox.defaultValue)
    }
  }

  /**
   * 
   * @param prevProps 
   * @param prevState 
   * @param snapshot 
   */
  componentDidUpdate(prevProps: Readonly<OptionsProps>, prevState: Readonly<OptionsState>, snapshot?: any): void {
    const {
      subscription, 
      setPaymentDate, 
      donationType, 
      donationAmount, 
      plans,
      initialUrlParams: {
        amount,
        isMonthly,
      },
      layoutConfig,
    } = this.props

    if (!prevProps.subscription && subscription) {
      const paymentDate = new Date(subscription.nextPaymentDate || subscription.startDate)
      setPaymentDate(paymentDate.getUTCDate() || (new Date()).getDate())
    }
    
    if ((prevProps.donationType !== donationType) || (prevProps.donationAmount !== donationAmount)) {
      const setAmount = donationAmount || amount || Number(plans[donationType][isMonthly ? 1 : 0])

      setDonationType(donationType)
      setDonationAmount(Number(setAmount))
      this.props.router.standardizeDonateRoute(donationType, setAmount)      
    }
    
    if ((prevProps.donationType !== this.props.donationType) && ['monthly', 'yearly'].includes(this.props.donationType)) {
      this.syncPaymentDateWithSubscription()
    }

    if (prevProps.layoutConfig?.roundUpCheckbox?.defaultValue === undefined && layoutConfig?.roundUpCheckbox?.defaultValue !== undefined) {
      this.props.setIncludeProcessingFee(layoutConfig.roundUpCheckbox.defaultValue)
    }
  }

  submitOptions = (donationAmount) => {
    if (donationAmount) {
      this.props.setDonationAmount(donationAmount)
      this.props.setValidationError({ donationAmount: '' })
    }
    else {
      this.props.setValidationError({ donationAmount: 'This field is required' })
    }
  } 

  selectDonationType = donationType => {
    const { setDonationType, setDonationAmount, plans } = this.props
    setDonationType(donationType)
    if (donationType !== this.props.donationType) {
      const isMonthly = donationType === 'monthly'
      setDonationAmount(plans[donationType][isMonthly ? 1 : 0])
    }
  }

  formatChargedPaymentProfile = subscription => {
    let creditCard, bankAccount
    switch (subscription.paymentGateway) {
      case 'Authorize.NET':
        creditCard = _.get(subscription, ['profile', 'paymentProfile', 'payment', 'creditCard'])
        bankAccount = _.get(subscription, ['profile', 'paymentProfile', 'payment', 'bankAccount'])
        return (
          creditCard && `credit card ${creditCard.cardNumber}`
        ) || (
          bankAccount && `bank account ${bankAccount.accountNumber} (routing ${bankAccount.routingNumber}, holder ${bankAccount.nameOnAccount})`
        )
      default:
        if (!subscription.payment) {
          return `an expired credit card / bank account`
        }
        if (subscription.payment.object === 'payment_method') {
          switch (subscription.payment.type) {
            case 'card':
              return `credit card XXXX ${subscription.payment.card.last4}`
            case 'us_bank_account':
              return `bank account ${subscription.payment.us_bank_account.last4} (${subscription.payment.us_bank_account.bank_name}`
            default:
              return ''
          }
        }
        return subscription.payment.object === 'card' ? (
          `credit card XXXX ${subscription.payment.last4}`
        ) : (
          `bank account ${subscription.payment.last4} (routing ${subscription.payment.routing_number}` +
          subscription.payment.account_holder_name ? `, holder ${subscription.payment.account_holder_name}` : ''
        )
    }
  }   
  syncPaymentDateWithSubscription = () => {
    const { donationType, setPaymentDate, setPaymentMonth } = this.props
    const subscription = this.props[donationType === 'yearly' ? 'subscriptionYearly' : 'subscription']
    if (subscription && subscription.npe03__next_payment_date__c) {
      const nextPaymentDate = moment(subscription.npe03__next_payment_date__c)
      setPaymentDate(nextPaymentDate.date())
      if (donationType === 'yearly') {
        setPaymentMonth(nextPaymentDate.month())
      }
    }
  }



  focusOtherAmountInput = () => {
    setTimeout(() => {
      const otherAmountInput = document.getElementById('other-amount-input')
      const donateTypeContainer = document.getElementById('donate-type-container')

      if (donateTypeContainer && otherAmountInput) {
        donateTypeContainer.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
          
        // Back up
        // const fakeInput = document.createElement('input')
        // fakeInput.setAttribute('type', 'text')
        // fakeInput.style.position = 'absolute'
        // fakeInput.style.opacity = 0
        // fakeInput.style.height = 0
        // fakeInput.style.fontSize = '16px' // disable auto zoom
        // document.body.prepend(fakeInput)
        // setTimeout(() => {
        //   otherAmountInput.dispatchEvent(new Event('touchstart'))
        //   otherAmountInput.focus()
        //   // otherAmountInput.click()
        //   fakeInput.remove()
        // }, 100)

        const f = (event) => {
          setTimeout(() => { 
            otherAmountInput.focus()
              event.preventDefault()
          })
        }
        let mobile = false
        donateTypeContainer.addEventListener('click', (event) => {
            if(mobile) return
            f(event)
        })

        donateTypeContainer.addEventListener('touchstart', (event) => {
            mobile = true
            f(event)
        })

        donateTypeContainer.addEventListener('touchend', (event) => {
            event.preventDefault()
            event.stopPropagation()
        })

        donateTypeContainer.click()
      }
    }, 100)
  }

  giftDescription = (site, cid) => {
    switch (site) {
      case 'STS':
        return (
          <p>
            {`Because 4 out of 5 women who board a Stork Bus choose life`}
            <sup>1</sup>
            {
              cidTextDict.STS[cid] ||
              ` - you can be certain your donation is effective. Your gift will help fund more Stork Buses and keep their services FREE of charge to the women who most need them. With your first gift of any amount, we’ll send you a `
            }
            {/* <span id="gift-banner-text">Save the Storks sticker (pictured right)</span> */}
            {/* <a id="gift-banner-anchor" href="#save-the-storks-gift">Save the Storks sticker</a> */}
            {`Save the Storks sticker`}
            {` as a way to remember the life-saving work you are accomplishing. Thank you for your generosity today!`}
          </p>
        )
      case 'IHH':
        return (
          <p style={{paddingTop: '20px'}}>
            When you donate to In His Hands, you are directly impacting the spread of hope and healing to hundreds of women who have experienced the devastating loss of a child.
          </p>
        )
      case 'OPH': 
        return (
          <p style={{paddingTop: '20px'}}>
            For over 37 years, individuals like you have a made a difference in our communities by helping Options for Her to provide quality healthcare, pregnancy counseling, education and hope to those with unplanned pregnancies.
            When you make a donation to Options for Her, you aren't just making a donation, you are saving lives.
          </p>
        )
      default:
        return null
    }
  }

  

  render() {
    const urlQuery = queryString.parse(location.search)
    const { donationType = urlQuery.term || 'monthly', plans } = this.props
    const isMonthly = donationType === 'monthly'
    const {
      customer,
      loadingCustomer,
      donationAmount = Number(urlQuery.amount || plans[donationType][isMonthly ? 1 : 0]),
      validationError,
      inputDonationAmount,
      setDonationAmount,
      subscription,
      subscriptionYearly,
      showDetails,
      setShowDetails,
      monthlyOtherAmount,
      setMonthlyOtherAmount,
      paymentDate,
      paymentMonth,
      setPaymentDate,
      setPaymentMonth,
      displayValidationError,
      onlyShowTypes,
      guestMode,
      processingFee,
      includeProcessingFee,
      setIncludeProcessingFee,
      site,
      initialUrlParams = {},
      campaignId,
      isAdminTerminal,
      utmVideo,
      headerVideo,
      charityRun: { attendees, ready: charityRunReady },
      optionsErrors,
      layoutConfig,
      campaignOptions,
      selectedCampaignOption,
      setData,
    } = this.props
    const errors = optionsErrors || []
    const {
      showTaxStatus,
      giftModalOpen,
      decimal,
    } = this.state
    const donationTypes = onlyShowTypes.length ? onlyShowTypes : Object.keys(donationTypeNames)
    const reversedDonationTypes = donationTypes.map((t, index) => donationTypes[donationTypes.length - index - 1])

    if (!plans) return <Spinner />

    let roundedUp;
    if (donationAmount !== 'other') {
      roundedUp = (Number(donationAmount) + Number(processingFee)).toFixed(2)
    }

    let minimumRequired = charityRunReady ? 35 * attendees.length : 5;
    if (!minimumRequired) minimumRequired = 35

    const headlineLayoutConfig = layoutConfig?.headline || {}
    const giftDescriptionLayoutConfig = (layoutConfig || {})[`gift_descriptions_${donationType}`] || layoutConfig?.giftDescriptions
    const giftOptions = campaignOptions || layoutConfig?.giftOptions
    const campaignOptionMenu = (giftOptions && !!layoutConfig && selectedCampaignOption) ? (
      <div>
        <GiftMenu
          giftOptions={giftOptions}
          selectedCampaignOption={selectedCampaignOption}
          onClick={option => setData({ field: 'selectedCampaignOption', val: { ...selectedCampaignOption, option } })}
          inline={true}
          />
      </div>
    ) : null
    return (
      <section className='options'>
        { loadingCustomer ? <Spinner mask={true} hideicon={true} /> : null }
        {
          guestMode ? (
            <div>
                {
                  headlineLayoutConfig[`logo_${donationType}`] ? (
                    <img 
                      className='float-right'
                      src={headlineLayoutConfig[`logo_${donationType}`]} 
                      style={headlineLayoutConfig[`logo_${donationType}_style`] || null}
                    />
                  ) : null
                }
              <h3 
                className='donate-headline' 
                style={(layoutConfig?.headline || {})[`title_${donationType}_style`] || layoutConfig?.headline?.titleStyle || null}
              >
                {
                  headlineLayoutConfig[`title_${donationType}`] || headlineLayoutConfig.title || (
                    site !== 'IHH' ? 'Donate Now' : <div style={{ fontFamily: 'Eyesome Script', fontWeight: 500 }}>Why Donate?</div>
                  )
                }
              </h3>
              {
                headlineLayoutConfig.subtitle ? (
                  <h4 className='donate-headline-subtitle'>
                    {headlineLayoutConfig[`subtitle_${donationType}`] || headlineLayoutConfig.subtitle}
                  </h4>
                ) : null
              }
              {
                headlineLayoutConfig[`hero_image_${donationType}`] ? (
                  <img 
                    src={headlineLayoutConfig[`hero_image_${donationType}`]}
                    style={headlineLayoutConfig[`hero_image_${donationType}_style`]}
                  />
                ) : null
              }
              {
                (headerVideo || utmVideo) ? (
                  <ReactPlayer 
                    id='videoPlayer' 
                    className="mb2"
                    controls width='100%' height='unset'
                    url={headerVideo || utmVideo} 
                    style={{ aspectRatio: '16/9' }}
                  />
                ) : null
              }
              {
                giftDescriptionLayoutConfig ? giftDescriptionLayoutConfig.map((p,i) => (
                  <p key={i}>{p}</p>
                )) : (
                  sitesWithDonateDescription.includes(site) && !isAdminTerminal && (
                    this.giftDescription(site, campaignId || initialUrlParams.cid || getCookie('cid'))
                  )
                )
              }
              <br />
            </div>
          ) : (
            <div>
              {
                headerVideo ? (
                  <ReactPlayer 
                    id='videoPlayer' 
                    className="mt3"
                    controls width='100%' height='unset'
                    style={{ aspectRatio: '16/9' }}
                    url={headerVideo} 
                  />
                ) : null
              }
              <legend style={showTaxStatus ? {paddingBottom: 0, marginBottom: 0} : {}}>Donation Amount</legend>
            </div>
          )
        }
        {
          showTaxStatus ? (
            <div style={{ marginBottom: 40, paddingTop: 2 }}>
              <small>Save the Storks® is a 501 (c)(3) EIN: 461031815</small>
            </div>
        ): null
        }
        {
          (subscription && (donationType === 'monthly')) ? (
            <p>
              <br />
              <span>
                {`You are currently donating $${(subscription.amount || 0).toFixed(2)} per month using ${this.formatChargedPaymentProfile(subscription)}.`}
              </span>
            </p>
          ) : null
        }
        {
          (subscriptionYearly && (donationType === 'yearly')) ? (
            <p>
              <br />
              <span>
                {`You are currently donating $${(subscriptionYearly.amount || 0).toFixed(2)} per year using ${this.formatChargedPaymentProfile(subscriptionYearly)}.`}
              </span>
            </p>
          ) : null
        }
        <Form.Group id="donate-type-container">
          
              <div className="flex pb-3">
                {
                  reversedDonationTypes.map((key, index) => (
                    <Button
                      key={key}
                      style={index ? { marginLeft: 12 } : null}
                      className={`flex-1 btn-option ${donationType === key ? 'selected' : ''}`}
                      onClick={() => this.selectDonationType(key)}
                    >
                      {donationTypeNames[key]}
                    </Button>
                  ))
                }
              </div>
            
          
          
        </Form.Group>
        {
          guestMode && (layoutConfig?.amountImpact?.visible !== false) && (
            <Form.Group className="right option-highlight" style={{margin: '10px 0px'}}>
              <span className='hightlighted'>{`${Number(donationAmount) ? ('$' + donationAmount) : 'Your donation'} would have a huge impact!`}</span>
            </Form.Group>
          )
        }
        <Form.Group className={`flex flex-wrap${errors.some(e => e.field === 'donationAmount') ? ' has-error' : ''}`}>
        {
            plans[donationType].reduce((acc, amount, index) => {
              const break2 = !((index+1) % 2), break3 = !((index+1) % 3)
              const breakClassName = `break${break2 ? '-2' : ''}${break3 ? '-3' : ''}`
              const lastItem = index === plans[donationType].length - 1
              return [
                ...acc,
                (
                  <div className={`donate-option ${(break2 || break3) ? 'item-' + breakClassName : ''}`} key={index}>
                    {
                      amount ? (
                        <Button
                          className={`full-width btn-option ${(monthlyOtherAmount ? false : (donationAmount === amount)) ? 'selected' : ''}`}
                          onClick={() => {
                            setDonationAmount(amount)
                            setMonthlyOtherAmount(false)
                          }}
                        >
                          {`$${numeral(amount).format('0,0')}`}
                        </Button>
                      ) : (
                        <>
                        {/* <InputGroup 
                          style={{ height: 44}} 
                          className={(displayValidationError && !donationAmount) ? 'has-error' : ''}
                          size='xs'
                        > */}
                        {/* <InputGroup.Text>$</InputGroup.Text> */}
                          {/* <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div> */}
                            <FloatingLabel label="Other"
                              className={`option-other ${monthlyOtherAmount ? 'selected' : ''}`}
                            >
                            <div className="left-inner-addon">
                            <span>$</span>
                            </div>
                            <Form.Control
                              id="other-amount-input"
                              // style={{ height: 44 }}
                              placeholder="Other"
                              type="number"
                              name="donationAmount"
                              min={minimumRequired}
                              step={5}
                              value={monthlyOtherAmount ? (((donationAmount || '').toString()) || '').replace(decimal, '') + decimal : ''}
                              onChange={(e) => {
                                if (!monthlyOtherAmount) {
                                  setMonthlyOtherAmount(true)
                                  inputDonationAmount(null)
                                }
                                const nextDecimal = ((e.target.value.match(/\.\d*/) || [])[0] || '').slice(0,3)
                                if (nextDecimal.length > 3) return
                                if (nextDecimal.length == 3 && nextDecimal[2] === '0') return
                                this.setState({ decimal: nextDecimal })
                                inputDonationAmount(!isNaN(Number(e.target.value)) ? e.target.value.trim() : null)
                                e.currentTarget.classList.remove('is-invalid')
                                e.currentTarget.parentElement?.parentElement?.classList.remove('has-error')
                              }}
                              onBlur={e => {
                                this.setState({ decimal: '', })
                              }}
                              isInvalid={errors.some(e => e.field === 'donationAmount')}
                            />
                            <Form.Control.Feedback type="invalid">{errors.find(e => e.field === `donationAmount`)?.message[0]}</Form.Control.Feedback>
                            </FloatingLabel>
                        {/* </div> */}
                        {/* </InputGroup> */}
                        </>
                      )
                    }
                  </div>
                ),
                (lastItem && !(break2 || break3)) ? <div className="flex-1" key={`flex-1-${index}`}>&nbsp;</div> : null,
                ((break2 || break3) && !lastItem) ? (
                  <div key={`break-${index}`} className={breakClassName}/>
                ) : null
              ]
            }, [])
          }
          { 
            monthlyOtherAmount && (donationAmount < minimumRequired) && (
              <div className="mt1 full-flex" style={{ color: '#a94442' }}>
                {`Please check and re-enter the donation amount (minimum $${minimumRequired})`}
              </div>
            )
          }
        </Form.Group>
        {
          ((donationType === 'monthly') && (layoutConfig?.paymentDateMenu?.visible !== false) && !guestMode) ? (
            <div>
              <label className="pre-field required mr2">Payment date on:</label>
              <DropdownButton
                title={dateTitles[paymentDate - 1]}
                id="payment-date-menu"
                className='d-inline-flex'
              >
                {
                  dates.map((d, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => setPaymentDate(d)}
                      active={d === paymentDate}
                    >
                      {dateTitles[i]}
                    </Dropdown.Item>
                  ))
                }
              </DropdownButton>
              <label className="pre-field ml2">of every month.</label>
            </div>
          ) : null
        }
        {
          ((donationType === 'yearly') && (layoutConfig?.paymentDateMenu?.visible !== false) && !guestMode) ? (
            <div>
              <label className="pre-field required mr2">Payment date on:</label>
              <DropdownButton
                title={dateTitles[paymentDate - 1]}
                id="payment-date-menu"
                className='d-inline-flex'
              >
                {
                  dates.slice(0, daysOfMonths[paymentMonth]).map((d, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => setPaymentDate(d)}
                      active={d === paymentDate}
                    >
                      {dateTitles[i]}
                    </Dropdown.Item>
                  ))
                }
              </DropdownButton>
              &nbsp;
              <DropdownButton
                title={monthTitles[paymentMonth]}
                id="payment-month-menu"
                className='d-inline-flex'
              >
                {
                  monthTitles.map((m, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => setPaymentMonth(i)}
                      active={m === paymentMonth}
                    >
                      {m}
                    </Dropdown.Item>
                  ))
                }
              </DropdownButton>
              <label className="pre-field ml2">of every year.</label>
            </div>
          ) : null
        }
        {
          (guestMode && (layoutConfig?.roundUpCheckbox?.visible !== false)) ? (
            <div>
              <Form.Check
                id="round-up"
                className="pre-field flex-children"
              >
                <Form.Check.Input
                  type='checkbox'
                  defaultChecked={includeProcessingFee} 
                  onChange={() => setIncludeProcessingFee(!includeProcessingFee)}
                />
                <Form.Check.Label>
                  <div className="flex v-center">
                    {`Round up my donation ${roundedUp} so the processing fees are covered`}
                  </div>
                </Form.Check.Label>
              </Form.Check>
            </div>
          ) : null
        }
        { campaignOptionMenu }
        {/* {
          customer ? (
            <div className="mt3">
              <Button className={showDetails ? 'btn-donate' : ''} onClick={() => setShowDetails(!showDetails)}>
                {`${showDetails ? 'Hide' : 'Show'} your profile details`}
              </Button>
            </div>
          ) : null
        } */}
      </section>
    )
  }
}

export default connector(withRouter(Options))
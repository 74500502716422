// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import axiosServer from '../modules/axiosServer';
import { User } from '@auth0/auth0-react';

interface RefreshBannerProps {
  profileAuth0?: User;
}

export const RefreshBanner: React.FC<RefreshBannerProps> = ({ profileAuth0 }) => {
  const [serverVersion, setServerVersion] = useState<string | null>(null)
  const [showBanner, setShowBanner] = useState(false)
  const buildTimestampMeta = document.querySelector('meta[name="build-timestamp"]')
  const currentTimestamp = buildTimestampMeta?.getAttribute('content')

  const checkVersion = async () => {
    try {
      const response = await axiosServer.get('/version');
      setServerVersion(response.data)
      
      if (currentTimestamp && response.data !== currentTimestamp) {
        setShowBanner(true)
      } else {
        setShowBanner(false)
      }
    } catch (error) {
      console.error('Failed to fetch version:', error)
    }
  };

  useEffect(() => {
    checkVersion()
    // Check version every 5 minutes if needed
    // const interval = setInterval(checkVersion, 5 * 60 * 1000)
    // return () => clearInterval(interval)
  }, [])

  const handleRefresh = () => {
    window.location.reload(true)
  }

  if (!showBanner || !profileAuth0) {
    return null
  }

  return (
    <div style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      background: '#f8f9fa',
      borderTop: '1px solid #dee2e6',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
      zIndex: 9999,
    }}>
      <span>A newer version of the Donor Portal is available. Please Refresh Now to update the app.</span>
      <Button className='btn-donate' variant="primary" onClick={handleRefresh}>
        Refresh Now
      </Button>
    </div>
  )
}

// @ts-nocheck

import React from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import { Dropdown } from 'react-bootstrap'

export const LoginButton = ({ saveAppState, email, redirectUri }) => {
  const { loginWithRedirect, loginWithPopup } = useAuth0()
  const options = {
    authorizationParams: {
      screen_hint: "login",
      login_hint: email || undefined,
      redirect_uri: redirectUri,
    },
  }

  return (
    <Dropdown.Item
      eventKey={'login'}
      onClick={async () => {
        saveAppState()
        if (window.top !== window) {
          try {
            await loginWithPopup(options)
          }
          catch (e) {
            console.error(e)
            if (e?.popup?.close) {
              e.popup.close()
            }
          }
          return
        }
        await loginWithRedirect(options)
      }}
    >
      Login
    </Dropdown.Item>
  )
}

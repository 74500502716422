// @ts-nocheck

import React, { useState } from 'react'
import { Container, Nav, NavItem, Navbar, NavDropdown, Button} from 'react-bootstrap'
import qs from 'query-string'
import { useAuth0 } from "@auth0/auth0-react"
import {
  siteLinkUrl,
  siteLogoStyles,
} from 'modules/siteContents'
import { constructRedirectUri } from 'modules/utils'

import { MainImage } from './MainImage'
import { HeaderNavigationDropDown } from './HeaderNavigationDropdown'

export const HeaderIHH = (props) => {
  const { saveAppState, email, isAdmin, search, successInfo, site, isAdminTerminal, utmCampaign, mainImage } = props
  const { loginWithRedirect, loginWithPopup, user } = useAuth0()
  const redirectUri = constructRedirectUri(qs.parse(search), location.pathname)
  const [show, setShow] = useState({ boxes: false, about: false, login: false });
  const showDropdown = id=>{
    setShow({ ...show, [id]: true })
  }
  const hideDropdown = id => {
    setShow({ ...show, [id]: false })
  }

  return (
    <div id="header">
      <Navbar
        bg="light"
        expand="lg"
        collapseOnSelect
      >
        <Container style={{ width: '100%' }}>
          <Navbar.Brand>
            <a href={siteLinkUrl[site] || null} style={{ paddingRight: 16 }}>
              <img 
                width="140" height="79" 
                style={siteLogoStyles[site]}
                src="/server/assets/images/header-brand.webp" 
                alt="" decoding="async" 
              />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse id="header-navbar-nav">
            <Nav
              className="flex full-flex"
              style={{
                maxWidth: '1200px',
                margin: 'auto',
                justifyContent: 'center',
              }}
            >
              <NavDropdown 
                title="OUR BOXES" 
                onClick={() => window.open('https://www.ihhministry.org/our-boxes-new', '_blank')}
                className='flex v-center mx2'
                show={show.boxes}
                onMouseEnter={() => showDropdown('boxes')} 
                onMouseLeave={() => hideDropdown('boxes')}
              >
                <NavDropdown.Item href="https://www.ihhministry.org/our-box">OUR BOX</NavDropdown.Item>
                <NavDropdown.Item href="https://www.ihhministry.org/shop">SHOP</NavDropdown.Item>
              </NavDropdown>
              <Nav.Item className='flex v-center mx2'>
                <Nav.Link href="https://www.ihhministry.org/get-involved">GET INVOLVED</Nav.Link>
              </Nav.Item>
              
              <NavDropdown 
                title="ABOUT"
                onClick={() => window.open('https://www.ihhministry.org/about', '_blank')}
                className='flex v-center mx2'
                show={show.about}
                onMouseEnter={() => showDropdown('about')} 
                onMouseLeave={() => hideDropdown('about')}
              >
                <NavDropdown.Item href="https://www.ihhministry.org/about-ihh">ABOUT IHH</NavDropdown.Item>
                <NavDropdown.Item href="https://www.ihhministry.org/contact-us">CONTACT US</NavDropdown.Item>
                <NavDropdown.Item href="https://www.ihhministry.org/our-story">OUR STORY</NavDropdown.Item>
              </NavDropdown>
              <Nav.Item className='flex v-center mx2'>
                <Nav.Link href="https://donate.ihhministry.org">DONATE</Nav.Link>
              </Nav.Item>
            </Nav>
            <Nav>
              { 
                user ? 
                  <HeaderNavigationDropDown 
                    show={show.login}
                    showDropdown={showDropdown}
                    hideDropdown={hideDropdown}
                    className='ml2'
                    {...props}
                  /> : (
                  <NavItem 
                    className='ml2 flex v-center link-item cursor-pointer' 
                    style={{ height: 70, display: 'flex', whiteSpace: 'nowrap' }}
                    onClick={async () => { 
                      const loginOptions = { authorizationParams: { screen_hint: 'login', redirect_uri: redirectUri, login_hint: email || undefined }}
                      if (window.top !== window) {
                        try {
                          await loginWithPopup(loginOptions)
                        }
                        catch (e) {
                          console.error(e)
                          if (e?.popup?.close) {
                            e.popup.close()
                          }
                        }
                        return
                      }
                      await loginWithRedirect(loginOptions)
                    }}
                  >
                    <Nav.Link>DONOR LOGIN</Nav.Link>
                  </NavItem>
                )
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {
        successInfo ? <hr /> : (
          <div className='center flex v-center' style={{ 
            minHeight: 120,
            paddingTop: 30,
            paddingBottom: 20,
            background: 'linear-gradient(rgba(0, 0, 0, 0.59), rgba(0, 0, 0, 0.59)), url(/server/assets/images/header-main.webp',
            backgroundSize: 'cover',
          }}>
            <div style={{ maxWidth: 750, margin: 'auto'}} className='px3'>
              {/* <h1 style={{ color: 'white', fontFamily: 'Eyesome Script', fontWeight: 500 }} className='mb2'>
                In His Hands is a 501c3 that uses prayerfully made gift boxes to inspire hope and healing in women who have experienced pregnancy loss.
              </h1> */}
            </div>
            
          </div>
        )
      }
      {/* <MainImage {...props}></MainImage> */}
    </div>
  )
}
import { Auth0Provider } from "@auth0/auth0-react";
import React, { useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { emitEvent } from "modules/events";

import type { AppState, User } from "@auth0/auth0-react";

export const AppProvider = ({ children, services, sandbox }) => {
  const navigate = useNavigate()
  const location = useLocation();

  const redirectUri = window.location.origin
  const onRedirectCallback = (state: AppState|undefined, user: User|undefined) : void => {
    const redirect = state?.redirectUri || window.location.pathname
    emitEvent(emitEvent.events.LOGIN, 
      {
        level:'info', 
        info: {
          user,
          state,
        },
        meta: {redirect}
      })
      .then(() => 
        navigate(redirect)
      )
  }

  let config = services.auth0
  if (import.meta.env.DEV) {
    // for dev and local - swap keys
    config = sandbox.auth0
  }

  // console.log('redirecturi set as', redirectUri);
  // for testing keys
  // config = {
  //   domain: 'savethestorks.us.auth0.com',
  //   client: 'p1rVqu67drxHWsf6cj2s5oPCe03Rm26r',
  // }

  useEffect(() => {
    emitEvent(emitEvent.events.PAGE_VIEW, { level: 'info', info: location})
  }, [location])

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.client}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: redirectUri,
        audience : `https://${config.domain}/api/v2/`,
        ...(config.organization ? {
          organization: config.organization
        } : null)
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

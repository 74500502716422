import React from 'react'
import Spinner from 'sharedComponents/Spinner'
import { useAuth0 } from "@auth0/auth0-react"
import AppRouter from './routes/App.Router'
import queryString from 'query-string'
import type { Site } from './types/sites'
import { RefreshBanner } from './sharedComponents/RefreshBanner'

console.log('[WINDOW INNER WIDTH]', window.innerWidth)
export const App : React.FC<Site> = (props) => {
  const { isLoading, user: profileAuth0, getAccessTokenSilently, loginWithRedirect, loginWithPopup } = useAuth0()

  // TODO: write function to validate domain
  const { search: query, pathname } = window.location
  const isLoginRedirect = query.includes('code=') && query.includes('state=')
  const {code, state, ...restUrlParams} = queryString.parse(query)
  const initialUrlParams = Object.keys(restUrlParams).reduce((acc, cur) => ({
    ...acc,
    [cur.toLowerCase()]: restUrlParams[cur],
  }), {})

  const auth0Config = {
    profileAuth0,
    getAccessTokenSilently,
    loginWithRedirect,
    loginWithPopup,
  }

  return (
    <>
      <RefreshBanner profileAuth0={profileAuth0}/>
      {isLoading ? <Spinner /> : (
        <AppRouter 
          {...auth0Config} 
          isLoginRedirect={isLoginRedirect} 
          initialUrlParams={initialUrlParams} 
          initialPath={pathname} 
          {...props}
        />
      )}
    </>
  )
}

export default App

import React, {useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from 'react-redux'

import { setTokenManagement } from "src/slices/accessSlice";

export const AccessTokenProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently, loginWithRedirect, loginWithPopup } = useAuth0()

  useEffect(() => {
    console.info('[provider][access] setAccessTokenSilently')

    dispatch(setTokenManagement({
      getAccessTokenSilently,
      loginWithRedirect,
      loginWithPopup,
    }))
  }, [])

  return (
    <>
      {children}
    </>
  )
}
